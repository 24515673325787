import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Project from "../components/Project"
import FrontTeaser from "../components/FrontTeaser"

export default function HomePage({ data }) {
  const [currentOpen, setCurrentOpen] = useState("")
  const [shuffledNodes, setShuffledNodes] = useState([])

  console.log(data.allWpProjekt)

  useEffect(() => {
    if (data.allWpProjekt) {
      setShuffledNodes(data.allWpProjekt.nodes.sort(() => 0.5 - Math.random()))
    }
  }, [])

  function toggleOpen(projectId) {
    console.log(projectId)
    console.log("toggle open")
    if (currentOpen === projectId) {
      setCurrentOpen("")
    } else {
      setCurrentOpen(projectId)
    }
  }

  return (
    <div className="container">
      <Helmet>
        <title>Thierry Seiler ・ Webseiten und Webapps, Zürich</title>
        <meta
          property="og:title"
          content="Thierry Seiler ・ Webseiten und Webapps, Zürich"
        />
        <meta
          property="og:description"
          content="Ich bin Ihr Partner für durchdachte Lösungen im Internet."
        />
      </Helmet>
      <FrontTeaser />
      <h3 className="mb-5">Bisherige Arbeiten</h3>
      <div className="row">
        {shuffledNodes.map(node => (
          <Project
            project={node}
            toggleOpen={toggleOpen}
            currentOpen={currentOpen}
            key={node.id}
          />
        ))}
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query AllProjekteData {
    allWpProjekt(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        content
        title
        acf_projekt {
          leistungen
          vimeoId
          websiteUrl
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`
