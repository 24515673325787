import styled from "styled-components"
import React from "react"

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .inner {
    @media (min-width: ${props => props.theme.sizes.sm}) {
      border-left: 2px solid white;
      padding-left: 15px;
      flex: 1 1 100%;
    }
  }

  h3 {
    @media (min-width: ${props => props.theme.sizes.sm}) {
      min-height: 2.5em;
    }
  }
`

export default function FrontTeaser() {
  return (
    <div className="mt-12 mt-md-30 mb-22">
      <div className="mb-12">
        <h1>
          Ich bin Ihr Partner
          <br /> für durchdachte Lösungen im Internet.
        </h1>
      </div>
      <div className="row my-12">
        <StyledDiv className="col col-12 col-md-4 mb-8 mb-md-0">
          <div className="inner">
            <h3 className="mb-2 mb-md-4">
              Für Geschäftsideen, die über das Web abgewickelt werden
            </h3>
            <ul>
              <li>Web-App von der Konzeption bis zur Programmierung.</li>
              <li>Helper-Tools zur Optimierung von Prozessen</li>
            </ul>
          </div>
        </StyledDiv>
        <StyledDiv className="col col-12 col-md-4 mb-8 mb-md-0">
          <div className="inner">
            <h3 className="mb-2 mb-md-4">
              Für Unternehmen mit Kommunikations&shy;bedarf
            </h3>
            <ul>
              <li>
                Komplette Webseite von der inhaltlichen Konzeption über die
                Gestaltung des Firmenauftritts bis zur Programmierung.
              </li>
              <li>In Zusammenarbeit mit Grafikern und Textern.</li>
            </ul>
          </div>
        </StyledDiv>
        <StyledDiv className="col col-12 col-md-4 mb-8 mb-md-0">
          <div className="inner">
            <h3 className="mb-2 mb-md-4">
              <b>Für Agenturen und GrafikerInnen</b>
            </h3>
            <ul>
              <li>Programmierung nach Designvorgaben</li>
              <li>Beratung bei Konzeption und Design/UI</li>
              <li>Redigieren und Abfüllen von Inhalten</li>
            </ul>
          </div>
        </StyledDiv>
      </div>
    </div>
  )
}
